$(document).foundation();

// =============================== Menu Icon Animation ==================================== //

$(function(){
	$('#offCanvas').on('opened.zf.offcanvas',function(){
		$('.menu-icon').addClass('closed');
	});
	$('#offCanvas').on('closed.zf.offcanvas',function(){
		$('.menu-icon').removeClass('closed');
	});
});


// ====================================== Magellan ======================================== //


$(function(){
    
    var scrollTime = 1.2;   
    var currentSection = -1;
    var chapter = $('.chapter');
    var pagination = $('#pagination .scale');

    var ratio = 1, start = 0, end = 1;
    var min = 0, max = 1, scale = 1;

    if(typeof(ScrollMagic)==='function') {
     	var controller = new ScrollMagic.Controller();

	    if($(window).width() > 750) {
	        $('.parallax').each(function(){
	            new ScrollMagic.Scene({
	                triggerElement: this,
	                triggerHook: 0
	            })
	            .setPin($(this))
	            .addTo(controller)
	            ;
	        });
	    }
	    
	    var scene = new ScrollMagic.Scene({
	        triggerElement: '#start',
	        triggerHook: 0.9
	    })
	    .setClassToggle($('#pagination'), 'active')
	    //.addIndicators()
	    .addTo(controller)
	    ;
    }

    function init(){

    	var chapters = [];
    	var markup = '';

    	if (chapter.length > 1) {
    		min = chapter.eq(0).offset().top;
    		max = chapter.eq(chapter.length-1).offset().top;
    		scale = max - min;

    		chapter.each(function(key){
	    		chapters.push({offset: (chapter.eq(key).offset().top-min)/scale*100,
	    									id: chapter.eq(key).attr('id')});
	    	});


    		markup += '<ul>';
    		$(chapters).each(function(key){
    			markup += '<li style=\" left:'+ chapters[key].offset +'%;\"><a href="#'+ chapters[key].id +'">'+ chapters[key].id +'</a>';
    		});
    		markup += '</ul>';
    		pagination.html(markup);


    		ratio = pagination.width()/scale;
    		start = -1*chapter.eq(0).offset().top/scale;
    		$('.loco').css('left', start);

    		$('#pagination a').on('click', smoothAnchorScroll);

    		return true;

    	} else {
    		return false;
    	}
    }

    function locoPosition() {

    	var acceleration = 1;
    	switch ($('.loco').attr('id')) {
    		case 'nineteen': 
    			acceleration = 1.55;
    			break;
    		case 'twenty':
    			acceleration = 1.6;
    			break;
    		case 'twentyone':
    			acceleration = 1.2;
    			break;
    	}

    	var position = (start + $(document).scrollTop()/scale)*100;

    	if (position > 100) position *= acceleration;

    	$('.loco').css('left', position + '%');
    }

    function smoothAnchorScroll(event) {

		  
		    var linkRef=$(this).attr('href');

		    if(linkRef) {

			    $('body,html').animate({
			    	scrollTop: $(linkRef).offset().top
			    }, 500);

			    event.preventDefault();
		    }

    }

    //init();

    $(window).on('load resize', init);
    $(window).on('scroll', locoPosition);
    

});



//<li><a href="#">1</a></li>

// ====================================== Pagination ======================================== //



